<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <div class="column is-5-desktop is-8-tablet">
            <div class="card px-6 py-4">
              <div class="card-content">
                <div class="block has-text-centered">
                  <img class="logo_img" src="@/assets/logo_barta.png" alt="Barta">
                </div>
                <div class="block mb-5 mt-6">
                  <h1 class="font_20 has_gray has-text-weight-bold has-text-centered">
                    INICIAR SESIÓN
                  </h1>
                </div>
                <form @submit.prevent="loginUser" class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field control">
                      <label class="label has_gray" for="email">
                        Correo electrónico
                      </label>
                      <input
                        id="email"
                        class="input"
                        type="text"
                        name="email"
                        v-model="email"
                        placeholder="correo@ejemplo.mx"
                        v-validate="'email|required'"
                        data-vv-as="del correo electrónico"
                      >
                      <span v-show="vverrors.has('email')" class="help is-danger">{{ vverrors.first('email') }}</span>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="field control">
                      <label class="label has_gray" for="password">
                        Contraseña
                      </label>
                      <input
                        id="password"
                        class="input"
                        type="password"
                        name="password"
                        placeholder="********"
                        v-model="password"
                        v-validate="'required'"
                        data-vv-as="de la contraseña"
                        autocomplete="on"
                      >
                      <span v-show="vverrors.has('password')" class="help is-danger">{{ vverrors.first('password') }}</span>
                    </div>
                  </div>
                  <div class="column is-12 mb-2 mt-5">
                    <div class="block has-text-centered block_btn">
                      <button
                        type="submit"
                        class="button btn_violet"
                        :class="{ 'is-loading' : loading }"
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  </div>
                  <div class="column is-12">
                    <p class="font_12 has_gray has-text-centered mb-3">
                      ¿Olvidaste tu contraseña? <router-link class="has_violet has-text-weight-semibold" to="/forgot-password">Recupérala aquí</router-link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  data () {
    return {
      loading: false,
      email: null,
      password: null
    }
  },
  methods: {
    ...mapActions(['login']),
    async loginUser () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          email: this.email,
          password: this.password
        }
        await this.login(data)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .hero {
    background-color: #f7f7fa;
  }
  .card {
    border-radius: 8px;
    box-shadow: 0 2px 55px 0 rgba(188, 188, 222, 0.27);
    background-color: #fdfdfd;
  }
  .logo_img {
    width: 115px;
  }
  .btn_violet {
    width: 175px;
  }
  @media screen and (max-width: 768px) {
  }
</style>
